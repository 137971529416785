import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

const swiper = new Swiper('.swiper', {
    autoplay: {
        delay: 3000,
    },
    speed: 1500,
    effect: 'fade',
    fadeEffect: {
        // crossFade: true
    },
});
